import React from "react";
import { Link } from "gatsby";

// Heroicons
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";

// SVG
import AritaeLogo from "../../../images/logo/logo.inline.svg";
import AritaeLetters from "../../../images/logo/logo-letters.inline.svg";

const navigation = {
  solutions: [
    { name: "Marketing", href: "#" },
    { name: "Analytics", href: "#" },
    { name: "Commerce", href: "#" },
    { name: "Insights", href: "#" },
  ],
  aritaeDesc: [
    {
      message:
        "ARITAE is a community of like-hearted and like-minded individuals with an inspirational mindset that helps guide young people to find the clarity, focus, and power needed to unleash their potential.",
    },
  ],
  company: [
    { name: "About", href: "/about" },
    { name: "Academy", href: "/academy" },
    { name: "Contact", href: "/contact" },
    { name: "FAQ", href: "/faq" },
    { name: "Testimonials", href: "/testimonials" },
  ],
  subs: [
    { name: "Players", href: "/academy/players" },
    { name: "Coaches", href: "/academy/coaches" },
    { name: "Parents", href: "/academy/parents" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "/coming-soon",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/aritaeacademy/",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "/coming-soon",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "YouTube",
      href: "/coming-soon",
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          {...props}
          viewBox="0 0 24 24"
        >
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-slate-600" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-10xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-4 ">
          <Link to="/" className={"flex flex-col items-center "}>
            <AritaeLogo fill={"white"} className={"h-auto w-32 mx-auto pb-2"} />
            <AritaeLetters fill={"white"} className={"h-auto w-32 mx-auto"} />
          </Link>
          <div>
            <div className="mt-12 md:mt-0">
              <ul className="mt-4 space-y-4">
                {navigation.aritaeDesc.map((item) => (
                  <li key={item.message}>
                    <p className="text-base text-white">{item.message}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h3 className="text-xl font-semibold text-white tracking-wider uppercase">
                SITE LINKS
              </h3>
              <ul className="mt-4 space-y-2">
                {navigation.company.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-base text-white hover:text-gray-300"
                    >
                      {item.name}
                    </Link>
                    {item.name === "Academy" ? (
                      <ul className="text-white pt-2 text-sm">
                        {navigation.subs.map((item) => (
                          <li key={item.name}>
                            <ArrowNarrowRightIcon className="h-3 inline pr-2" />
                            <Link
                              className="my-2 hover:text-gray-300"
                              to={item.href}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p></p>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white tracking-wider uppercase">
              SOCIAL MEDIA
            </h3>
            <div className="flex space-x-6 md:order-2 pt-4">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-white hover:text-gray-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <p className="text-xs text-gray-400 max-w-sm text-center pb-2">
            &copy; Copyright 2003 Morrissey Coaching. All rights reserved. Any
            reproduction without written permission is strictly forbidden.
            Revised 2019, 2022 Aritae, LLC.
          </p>
          <Link
            to="/privacy-policy-terms-of-service"
            className="text-xs text-gray-400 hover:underline"
          >
            Privacy Policy & Terms of Service
          </Link>
        </div>
      </div>
    </footer>
  );
}

import * as React from "react"

// React
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "gatsby"

// SVG
import AritaeLogo from "../../../images/logo/logo.inline.svg";
import AritaeLetters from "../../../images/logo/logo-letters.inline.svg";

// Heroicons
import {
    HomeIcon,
    AcademicCapIcon,
    ChatIcon,
    BookOpenIcon,
    QuestionMarkCircleIcon,
    UserCircleIcon,
    MenuIcon,
    XIcon,
    UserGroupIcon,
    HeartIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

const academy = [
    {
        name: "Academy",
        order: 3,
        description:
            "The ARITAE Self-Leadership Academy is a module-based program of reflection, strategy and execution.",
        href: "/academy",
        icon: AcademicCapIcon,
    },
    {
        name: "Players",
        order: 4,
        description: "ARITAE Players are young adults that strive to find the clarity, focus, and power to reach their potential.",
        href: "/academy/players",
        icon: UserCircleIcon,
    },
    {
        name: "Coaches",
        order: 5,
        description: "ARITAE Coaches lead Players through the Self-Leadership Academy and inspire those around them to reach their potential.",
        href: "/academy/coaches",
        icon: UserGroupIcon,
    },
    {
        name: "Parents",
        order: 6,
        description:
            "The ARITAE Self-Leadership Academy can help your child find clarity, focus, and power. Find out if the program is right for them.",
        href: "/academy/parents",
        icon: HeartIcon,
    },
];

const mainNavLinks = [
    {
        name: "Home",
        order: 1,
        href: "/",
        icon: HomeIcon,
    },
    {
        name: "About",
        order: 2,
        href: "/about",
        icon: BookOpenIcon,
    },
    { name: "Contact",
        order: 7,
        href: "/contact",
        icon: ChatIcon
    },
    {
        name: "FAQ",
        order: 8,
        href: "/faq",
        icon: QuestionMarkCircleIcon,
    },
];

const navLinks = [...academy, ...mainNavLinks].sort((a, b) => a.order - b.order);

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Header() {
    return (
        <Popover className="absolute w-full top-0 z-50 bg-white/0">
            <div
                className="absolute inset-0 z-30 pointer-events-none"
                aria-hidden="true"
            />
            <div className="relative z-20">
                <div className="max-w-10xl w-auto mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-end md:space-x-10">
                    <div className={"flex"}>
                        <Link to="/" className="flex space-x-3 justify-center">
                            <AritaeLogo fill="white" className={"h-max w-auto"} />
                            <AritaeLetters
                                fill="white"
                                className="hidden lg:flex h-10 w-40"
                            />
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                        <Popover.Group className="flex space-x-10 "></Popover.Group>
                        <div className="flex items-center md:ml-12">
                            <Popover.Group as="nav" className="flex space-x-10 ">
                                <Link
                                    to="/about"
                                    className="{(navData) => (navData.isActive ? 'active' : 'none')} text-base uppercase font-bold border-b border-transparent text-white hover:transition-all hover:border-b hover:border-white"
                                >
                                    About
                                </Link>
                                <Popover>
                                    {({ open }) => (
                                        <>
                                            <Popover.Button
                                                className={classNames(
                                                    open ? "text-white" : "text-white",
                                                    "inline-flex items-center text-base font-bold uppercase hover:transition-all hover:border-b hover:border-white"
                                                )}
                                            >
                                                <span>Academy</span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? "text-white" : "text-white",
                                                        "ml-2 h-5 w-5 hover:transition-all hover:text-xl"
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 -translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 -translate-y-1"
                                            >
                                                <Popover.Panel className="hidden md:block absolute z-10 top-full mx-12 rounded-md inset-x-0 transform shadow-lg bg-white bg-opacity-50 backdrop-blur-md">
                                                    <div className="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                                                        {academy.map((item) => (
                                                            <Link
                                                                key={item.name}
                                                                to={item.href}
                                                                className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50/50"
                                                            >
                                                                <div className="flex md:h-full lg:flex-col">
                                                                    <div className="flex-shrink-0">
                                    <span className="inline-flex items-center justify-center h-10 w-10 text-black sm:h-12 sm:w-12">
                                      <item.icon
                                          className="h-6 w-6"
                                          aria-hidden="true"
                                      />
                                    </span>
                                                                    </div>
                                                                    <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                                                        <div>
                                                                            <p className="text-base font-medium text-gray-900">
                                                                                {item.name}
                                                                            </p>
                                                                            <p className="mt-1 text-sm text-gray-900">
                                                                                {item.description}
                                                                            </p>
                                                                        </div>
                                                                        <p className="mt-2 text-sm font-medium text-black lg:mt-4">
                                                                            Learn more{" "}
                                                                            <span aria-hidden="true">&rarr;</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>

                                <Link
                                    to="/faq"
                                    className="{(navData) => (navData.isActive ? 'active' : 'none')} text-base uppercase font-bold text-white hover:transition-all hover:border-b hover:border-white"
                                >
                                    FAQ
                                </Link>
                                <Link
                                    to="/contact"
                                    className="{(navData) => (navData.isActive ? 'active' : 'none')} text-base uppercase font-bold text-white hover:transition-all hover:border-b hover:border-white"
                                >
                                    Contact
                                </Link>
                                <Link
                                    to="/coming-soon"
                                    className="{(navData) => (navData.isActive ? 'active' : 'none')} text-base uppercase font-bold text-white hover:transition-all hover:border-b hover:border-white ml-10"
                                >
                                    Sign in
                                </Link>
                            </Popover.Group>
                        </div>
                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
                        <div className="pt-5 pb-6 px-5 sm:pb-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <AritaeLogo className={"h-10 w-auto fill-black"} />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-10 sm:mt-10">
                                <nav>
                                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                                        {navLinks.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="text-black">
                                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <div className="ml-4 text-base font-medium text-gray-900">
                                                    {item.name}
                                                </div>
                                            </Link>
                                        ))}


                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="pb-6 px-5">
                            <div className="mt-6">
                                <Link
                                    to="/signup"
                                    className="w-full flex items-center justify-center px-5 py-3 shadow-sm text-base font-medium text-white bg-black"
                                >
                                    Sign up
                                </Link>
                                <p className="mt-6 text-center text-base font-medium text-gray-500">
                                    Existing customer?{" "}
                                    <Link to="/login" className="text-black underline">
                                        Sign in
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
